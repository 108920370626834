import { AppState } from '@core/states/app.state';
import { createSelector } from '@ngrx/store';
import { AuthState } from '@core/states/auth/auth.state';
import { Role } from '@core/states/auth/enums/role';
import { hasRole } from '@core/helpers/permissions/has-role';

export const selectAuth = (state: AppState) => state.auth;
export const selectAuthUser = createSelector(
  selectAuth,
  (state: AuthState) => state.user
);
export const selectLoadStatus = createSelector(
  selectAuth,
  (state: AuthState) => state.loadStatus
);

export const selectError = createSelector(
  selectAuth,
  (state: AuthState) => state.error
);
export const selectErrorMessage = createSelector(
  selectError,
  error => error?.message
);

export const selectIsLoggedIn = createSelector(selectAuthUser, user => !!user);

export const selectAuthUserHasRole = (roles: Role[] = []) =>
  createSelector(selectAuthUser, user => hasRole(user, roles));
